<script setup lang="ts">
/**
 * @file The main differences between OzDropdownButton and OzContainedButton are:
 *
 * - Font weight
 * - Text color
 * - Transparent background instead of disabled color background
 * - No active state
 * - Padding
 * - Space between text and icon
 */
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import type { VueCssClass } from '@@/types'

withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    dir?: 'ltr' | 'rtl' | 'auto'
    sizePreset?: OzDropdownButtonSizePreset
    colorScheme?: OzDropdownButtonColorScheme
    text?: string
    /**
     * Name of the icon displayed inline start in the button.
     */
    startIconName?: string
    disabled?: boolean
    /**
     * Defines whether the button text should be truncated or not.
     * Use case:
     *
     * 1. `true` - truncate the button text if it exceeds the button's width
     * 2. `false` - do not truncate the button text, allowing it to stretch the button's height
     */
    shouldTruncateText?: boolean
    shouldBoldText?: boolean
    chevronDirection?: 'down' | 'up' | 'start' | 'end' | 'up_down'
    /**
     * Customizes the gap color between the button and the focus ring.
     */
    ringOffsetColorClasses?: VueCssClass
    /**
     * Customizes the text color on the button.
     */
    textColorClasses?: VueCssClass
    activeHighlightMode?: 'text' | 'background'
  }>(),
  {
    darkMode: 'auto',
    dir: 'auto',
    sizePreset: OzDropdownButtonSizePreset.H40px,
    colorScheme: OzDropdownButtonColorScheme.Secondary,
    text: undefined,
    startIconName: undefined,
    disabled: false,
    shouldTruncateText: false,
    shouldBoldText: false,
    chevronDirection: 'down',
    ringOffsetColorClasses: undefined,
    textColorClasses: undefined,
    activeHighlightMode: 'background',
  },
)

defineEmits(['click', 'focus', 'blur', 'mouseenter', 'mouseleave'])
</script>

<script lang="ts">
export enum OzDropdownButtonSizePreset {
  H40px = 'H40px',
  H32px = 'H32px',
  H20px = 'H20px',
}

export enum OzDropdownButtonColorScheme {
  Secondary = 'Secondary',
  SecondaryClear = 'SecondaryClear',
  SecondaryClearActive = 'SecondaryClearActive',
  SecondaryClearText = 'SecondaryClearText',
  SecondaryDark = 'SecondaryDark',
  TransparentSecondaryText = 'TransparentSecondaryText',
}

export enum OzDropdownButtonactiveHighlightMode {
  Background = 'background',
  Text = 'text',
}

export default {}
</script>

<template>
  <OzBaseButton
    :dir="dir"
    :disabled="disabled"
    :class="[
      'focus-visible:outline-none',

      // Reset user agent styles
      // Don't set px-0 here so we won't have specificity issues
      'py-0',

      // Centralize button content
      'flex',
      'items-center',
      'justify-start',
      'group',
      sizePreset === OzDropdownButtonSizePreset.H20px && [
        'h-5',
        'rounded-xl',
        'text-body-small',
        startIconName ? 'px-2' : 'px-3',
      ],

      sizePreset === OzDropdownButtonSizePreset.H32px && [
        'h-8',
        'rounded-xl',
        'text-body-small',
        startIconName ? 'px-2' : 'px-3',
      ],
      sizePreset === OzDropdownButtonSizePreset.H40px && [
        'h-10',
        'rounded-2xl',
        'text-body-posts',
        startIconName ? 'px-5' : 'ps-4 pe-3',
      ],

      colorScheme && [
        // Disabled
        'aria-disabled:bg-transparent',
        {
          'aria-disabled:text-dark-text-200': darkMode === false,
          'aria-disabled:text-light-text-200': darkMode === true,
          'aria-disabled:text-dark-text-200 dark:aria-disabled:text-light-text-200': darkMode === 'auto',
        },

        // Active
        !disabled &&
          activeHighlightMode === 'background' && {
            'hhover:active:bg-grape-500 hhover:active:text-light-text-100': darkMode === false,
            'hhover:active:bg-canary-500 hhover:active:text-dark-text-100': darkMode === true,
            'hhover:active:bg-grape-500 hhover:dark:active:bg-canary-500 hhover:active:text-light-text-100 hhover:dark:active:text-dark-text-100':
              darkMode === 'auto',
          },

        !disabled &&
          activeHighlightMode === 'text' && {
            'hhover:active:text-grape-500': darkMode === false,
            'hhover:active:text-canary-500': darkMode === true,
            'hhover:active:text-grape-500 hhover:dark:active:text-canary-500': darkMode === 'auto',
          },

        // Active styles for mobile devices
        activeHighlightMode === 'background' && {
          'active:bg-grape-500 active:text-light-text-100': darkMode === false,
          'active:bg-canary-500 active:text-dark-text-100': darkMode === true,
          'active:bg-grape-500 dark:active:bg-canary-500 active:text-light-text-100 dark:active:text-dark-text-100':
            darkMode === 'auto',
        },
        activeHighlightMode === 'text' && {
          'active:text-grape-500': darkMode === false,
          'active:text-canary-500': darkMode === true,
          'active:text-grape-500 dark:active:text-canary-500': darkMode === 'auto',
        },
      ],

      colorScheme === OzDropdownButtonColorScheme.SecondaryDark && [
        // Focus ring
        'ring-offset-1',
        'focus-visible:ring-2',
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },
        ringOffsetColorClasses || {
          // Tailwind uses box shadows for focus rings.
          // We'll have to deviate from TW paradigm if we want truly transparent ring-offset color.
          'ring-offset-white': darkMode === false,
          'ring-offset-grey-900': darkMode === true,
          'ring-offset-white dark:ring-offset-grey-900': darkMode === 'auto',
        },
        // Base
        {
          'bg-grey-100': darkMode === false,
          'bg-grey-700': darkMode === true,
          'bg-grey-100 dark:bg-grey-700': darkMode === 'auto',
        },
        textColorClasses || {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hhover:hover:bg-grey-200': darkMode === false,
          'hhover:hover:bg-grey-600': darkMode === true,
          'hhover:hover:bg-grey-200 hhover:dark:hover:bg-grey-600': darkMode === 'auto',
        },
      ],

      colorScheme === OzDropdownButtonColorScheme.Secondary && [
        // Focus ring
        'ring-offset-[1.5px]',
        'focus-visible:ring-[2.5px]',
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },
        ringOffsetColorClasses || {
          // Tailwind uses box shadows for focus rings.
          // We'll have to deviate from TW paradigm if we want truly transparent ring-offset color.
          'ring-offset-white': darkMode === false,
          'ring-offset-grey-900': darkMode === true,
          'ring-offset-white dark:ring-offset-grey-900': darkMode === 'auto',
        },

        // Background color
        {
          'bg-button-light': darkMode === false,
          'bg-button-dark': darkMode === true,
          'bg-button-light dark:bg-button-dark': darkMode === 'auto',
        },

        // Background hover color
        !disabled && {
          'hhover:hover:bg-button-hover-light': darkMode === false,
          'hhover:hover:bg-button-hover-dark': darkMode === true,
          'hhover:hover:bg-button-hover-light hhover:dark:hover:bg-button-hover-dark': darkMode === 'auto',
        },

        // Text color
        textColorClasses || {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],

      colorScheme === OzDropdownButtonColorScheme.SecondaryClearText && [
        // Focus ring
        'ring-offset-[1.5px]',
        'focus-visible:ring-[2.5px]',
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },
        ringOffsetColorClasses || {
          // Tailwind uses box shadows for focus rings.
          // We'll have to deviate from TW paradigm if we want truly transparent ring-offset color.
          'ring-offset-white': darkMode === false,
          'ring-offset-grey-900': darkMode === true,
          'ring-offset-white dark:ring-offset-grey-900': darkMode === 'auto',
        },

        // Background color
        {
          'bg-button-light': darkMode === false,
          'bg-button-dark': darkMode === true,
          'bg-button-light dark:bg-button-dark': darkMode === 'auto',
        },

        // Background hover color
        !disabled && {
          'hhover:hover:bg-button-hover-light': darkMode === false,
          'hhover:hover:bg-button-hover-dark': darkMode === true,
          'hhover:hover:bg-button-hover-light hhover:dark:hover:bg-button-hover-dark': darkMode === 'auto',
        },

        // Text color
        textColorClasses || {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
      ],

      colorScheme === OzDropdownButtonColorScheme.SecondaryClear && [
        // Focus ring
        'focus-visible:ring-2',
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },

        // Base
        'bg-transparent',
        textColorClasses || {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },

        // Hover
        {
          'hhover:bg-overlay-text-bg-light-mode-light-hover': darkMode === false,
          'hhover:bg-overlay-text-bg-dark-mode-dark-hover': darkMode === true,
          'hhover:bg-overlay-text-bg-light-mode-light-hover dark:hhover:bg-overlay-text-bg-dark-mode-dark-hover':
            darkMode === 'auto',
        },
      ],

      colorScheme === OzDropdownButtonColorScheme.SecondaryClearActive && [
        {
          'bg-overlay-text-bg-light-mode-light-hover': darkMode === false,
          'bg-overlay-text-bg-light-mode-dark-hover': darkMode === true,
          'bg-overlay-text-bg-light-mode-light-hover dark:bg-overlay-text-bg-light-mode-dark-hover':
            darkMode === 'auto',
        },
        textColorClasses || {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzDropdownButtonColorScheme.TransparentSecondaryText && [
        // Focus ring
        'focus-visible:ring-2',
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },

        // Base
        'bg-transparent',
        textColorClasses || {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },

        // Hover
        {
          'hhover:text-dark-text-100': darkMode === false,
          'hhover:text-light-text-100': darkMode === true,
          'hhover:text-dark-text-100 dark:hhover:text-light-text-100': darkMode === 'auto',
        },
      ],
    ]"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <OzIcon
      v-if="!!startIconName"
      :name="startIconName"
      :size="sizePreset === OzDropdownButtonSizePreset.H32px ? 16 : 20"
      class="me-1.5"
    />
    <slot v-else name="startAdornment"></slot>
    <span
      :class="[
        !disabled && 'me-1.5',
        shouldTruncateText && 'truncate',
        'text-start grow',
        'high-contrast:font-semibold',
        shouldBoldText && 'font-semibold',
      ]"
      >{{ text }}</span
    >
    <OzIcon
      v-if="!disabled"
      :name="chevronDirection === 'up_down' ? 'chevron_up_down' : 'disclosure'"
      :size="16"
      :class="[
        'justify-self-end',
        textColorClasses || {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        activeHighlightMode === 'background' && {
          'group-active:text-light-text-200': darkMode === false,
          'dark:group-active:text-dark-text-200': darkMode === true,
          'group-active:text-light-text-200 dark:group-active:text-dark-text-200': darkMode === 'auto',
        },
        activeHighlightMode === 'background' && {
          'hhover:group-active:text-light-text-200': darkMode === false,
          'hhover:dark:group-active:text-dark-text-200': darkMode === true,
          'hhover:group-active:text-light-text-200 hhover:dark:group-active:text-dark-text-200': darkMode === 'auto',
        },
        'transition-transform motion-reduce:transition-none',
        {
          '-rotate-90': chevronDirection === 'end',
          'rotate-90': chevronDirection === 'start',
          'rotate-180': chevronDirection === 'up',
        },
      ]"
    />
  </OzBaseButton>
</template>
